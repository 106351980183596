/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { loadFormInst, initFormModels, getTextLst, format } from '@/assets/app.js';
export default {
  name: "lcfqFwlc",
  components: {
    LCFQheader
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "发文流程",
      checkbox: false,
      showData: false,
      bpmSolution: {},
      processConfig: {},
      nodeIdUsers: {},
      formModels: [],
      minDate: new Date(2021, 0, 1),
      dqDate: new Date(),
      // gksxArr: [],
      // bygklyArr: [],
      solId: "",
      actDefId: "",
      data: {}
      // gksx:"",
      // bygkly:"",
      // bt: "",
      // mj: "",
      // hj: "",
      // jd: "",
      // fwzh: "",
      // fwrq: ""
    };
  },

  created() {
    this.$nextTick(() => {
      //从我的申请列表过来直接从缓存读取。
      let solId = this.$route.params.solId;
      this.initBySolInstId(solId);
    });
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
      });
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      if (typeof busad != "undefined") {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/customizeProc/postingproc/pOSTINGPROC/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          this.data = res.data;
          this.data.fwrq = format(this.data.fwrq);
        });
      } else {
        this.getUser();
      }
    },
    onConfirm(timestamp) {
      let year = timestamp.getFullYear();
      const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      const date = timestamp.getDate().toString().padStart(2, "0") + " ";
      const hours = timestamp.getHours() + ":";
      const minutes = timestamp.getMinutes() + ":";
      const seconds = timestamp.getSeconds();
      this.data.fwrq = year + "-" + month + "-" + date;
      this.showData = false;
    },
    datas() {
      var gksx;
      var bygkly;
      if (this.headTit == "我的草稿") {
        gksx = this.data.gksxArr;
        bygkly = this.data.bygklyArr;
      } else {
        gksx = getTextLst(this.data.gksxArr);
        bygkly = getTextLst(this.data.bygklyArr);
      }
      var jsonDataObj = {
        gksx: gksx,
        bygkly: bygkly,
        bt: this.data.bt,
        mj: this.data.mj,
        hj: this.data.hj,
        jd: this.data.jd,
        fwzh: this.data.fwzh,
        fwrq: this.data.fwrq,
        gksx_name: gksx,
        bygkly_name: bygkly
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      // http://192.168.1.54:8092/xzoa/bpm/core/bpmInst/saveDraft.do
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};